
const TYPE_NUMERIC = 'Numeric';
const TYPE_STRING = 'String';
const COMMA = ',';
const POINT = '.';
const EVENT_VALUE_CHANGE = 'valuechange';
const EVENT_VALUE_CHANGE_VALIDATED = 'valuechangevalidated';
const EVENT_VALUE_ERROR = 'valueerror';

export default class MyassaysParamsValue extends HTMLElement {
    constructor() {
        super();

        this._title = '';
        this._value = '';
        this._type = 'String';
        this._validation = '';
        this._decimalSeparator = '.';

        this._hasBeenRendered = false;

        this.dom = this;
    }

    static get observedAttributes() {
        return ['data-title', 'decimal-separator', 'data-value', 'data-validation'];
    }

    connectedCallback() {
        this.render();

        this.title = this.getAttribute('data-title');
        this.type = this.getAttribute('data-type');
        this.decimalSeparator = this.getAttribute('decimal-separator') || '.';
        this.value = this.getAttribute('data-value') || '';
        this.validation = this.getAttribute('data-validation') || '';

        this.configure();
        this._hasBeenRendered = true;
    }

    configure() {
        const inputElement = this.dom.querySelector('input');
        inputElement.addEventListener('change', evt => this.onInputChange(evt))
    }

    onInputChange(evt) {
        const rawValue = evt.target.value;
        this.dispatchEvent(new CustomEvent(EVENT_VALUE_CHANGE, {detail: rawValue, bubbles: true}));
        let isError = false;
        try {
            this.value = rawValue;
        } catch (error) {
            isError = true;
            this.dispatchEvent(new CustomEvent(EVENT_VALUE_ERROR, {detail: error.message, bubbles: true}));
        }
        if (!isError && this.validation) {
            this.validationFunction(this.value).then(error => {
                if (error) {
                    this.dispatchEvent(new CustomEvent(EVENT_VALUE_ERROR, {detail: error, bubbles: true}));
                } else {
                    this.dispatchEvent(new CustomEvent(EVENT_VALUE_CHANGE_VALIDATED, {detail: this.value, bubbles: true}));
                }
            });
        }
    }

    attributeChangedCallback(attrName, oldVal, newVal) {
        if (this._hasBeenRendered) {
            switch (attrName) {
                case "data-title":
                    this.title = newVal;
                    break;
                case "decimal-separator":
                    this.decimalSeparator = newVal;
                    break;
                case "data-value":
                    this.value = newVal;
                    break;
                case "data-validation":
                    this.validation = newVal;
                    break;
            }
        }
    }

    set title(value) {
        if (!value) {
            throw new Error('Please specify a title.');
        }
        if (typeof value !== 'string') {
            throw new Error('Title must be a string.');
        }
        this._title = value;
        this.dom.querySelector('label').innerHTML = this._title + ':';
    }

    get title() {
        return this._title;
    }

    set type(value) {
        if (value !== TYPE_NUMERIC && value !== TYPE_STRING) {
            throw new Error(`Type must be "${TYPE_NUMERIC}" or "${TYPE_STRING}".`);
        }
        this._type = value;
    }

    get type() {
        return this._type;
    }

    set value(value) {
        if (this._type === TYPE_STRING) {
            this._value = String(value || '').trim();
        } else {
            if (typeof value === 'string') {
                const numeric = Number(value.replace(/,/, '.'));
                if (isNaN(numeric)) {
                    throw new Error(`Value "${value}" is not a valid number.`);
                } else {
                    this._value = numeric;
                }
            } else if (typeof value === 'number') {
                this._value = value;
            } else {
                throw new Error(`Invalid value type: ${typeof value}.`);
            }
        }
        this.dom.querySelector('input').value = this.displayValue;
    }

    get value() {
        return this._value;
    }

    get displayValue() {
        return String(this._value).replace(/\./, this._decimalSeparator);
    }

    set decimalSeparator(value) {
        if (value !== POINT && value !== COMMA) {
            throw new Error('Decimal separator should be comma or point.');
        } else {
            this._decimalSeparator = value;
        }
    }

    get decimalSeparator() {
        return this._decimalSeparator;
    }

    set validation(value) {
        if (typeof value !== 'string') {
            throw new Error('The validation function name should be a string.');
        } else {
            this._validation = value;
        }
    }

    get validation() {
        return this._validation;
    }

    get validationFunction() {
        if (this._validation === '') {
            return value => new Promise(resolve => resolve(null));
        } else {
            const fn = window[this._validation];
            if (!fn) {
                throw new Error('The validation function was not found in the global namespace.');
            } else if(typeof fn !== 'function') {
                throw new Error('The validation function is of the wrong type.');
            } else {
                return fn;
            }
        }
    }

    render() {
        this.dom.innerHTML = `<label for="param-value"></label><input type="text" id="param-value"/>`;
    }
}
