import MyassaysParamsValue from './MyassaysParamsValue';

customElements.define('myassays-params-value', MyassaysParamsValue);

if (window.MyAssaysParamsValue !== undefined) {
    if (!(window.MyAssaysParamsValue instanceof Object)) {
        throw new Error('Could not create "window.MyAssaysParamsValue" namespace.');
    }
} else {
    window.MyAssaysParamsValue = {};
}
window.MyAssaysParamsValue = {
    ...window.MyAssaysParamsValue,
    getById: function (id) {
        const elem = document.getElementById(id);
        if (!elem) {
            throw `An instance of MyassaysParamsValue with the id "${id}" could not be found.`;
        } else if (! elem instanceof MyassaysParamsValue) {
            throw `The element with the id "${id}" is not a MyassaysParamsValue.`;
        } else {
            return elem;
        }
    },
    getValue: function (id) {
        return this.getById(id).value;
    },
    setValue: function (id, newValue) {
        this.getById(id).value = newValue;
    }
}
